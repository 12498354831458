// log
import store from "../store";

const fetchDataRequest = () => {
  return {
    type: "CHECK_DATA_REQUEST",
  };
};

const fetchDataSuccess = (payload) => {
  return {
    type: "CHECK_DATA_SUCCESS",
    payload: payload,
  };
};

const fetchDataFailed = (payload) => {
  return {
    type: "CHECK_DATA_FAILED",
    payload: payload,
  };
};

export const fetchData = () => {
  return async (dispatch) => {
    dispatch(fetchDataRequest());
    try {
      const blockchain = store
        .getState()
        .blockchain
      let totalSupply = await blockchain
        .smartContract.methods.totalSupply()
        .call();
      let maxMintSupply = await blockchain
        .smartContract.methods.maxMintAmount()
        .call();
      let paused = await blockchain
        .smartContract.methods.paused()
        .call();
      
      console.log(paused)

      dispatch(
        fetchDataSuccess({
          totalSupply,
          maxMintSupply,
        })
      );
    } catch (err) {
      console.log(err);
      dispatch(fetchDataFailed("Could not load data from contract."));
    }
  };
};
